<template>
  <div>
    <footer class="iq-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <!-- Copyright 2022 <a href="#">{{ $t('global.appName') }}</a> All Rights Reserved. -->
            {{ $t("footer.copyright") }}
          </div>
        </div>
      </div>
    </footer>
    <!-- <FloatingButton /> -->
  </div>
</template>
<script>
// import FloatingButton from './FloatingButton.vue'
export default {
  name: "LayoutFooter",
  // components: {
  //   FloatingButton
  // }
};
</script>
