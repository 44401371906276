<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="{ name: 'dashboard' }">
        <div class="iq-light-logo">
          <img
            src="../../../../assets/images/logo.png"
            class="img-fluid"
            alt="logo"
          />
        </div>
        <span>{{ $t("global.appName") }}</span>
      </router-link>
      <div class="left-icon-close align-self-center" @click="sidebarMini">
        <i class="ri-arrow-left-s-line"></i>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <CollapseMenu
          :items="filteredSidebar"
          :open="true"
          :sidebarGroupTitle="true"
        />
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import CollapseMenu from "../../menus/CollapseMenu";
import SideBarItems from "../../../../FackApi/json/SideBar";

export default {
  name: "SidebarStyle",
  components: {
    CollapseMenu,
  },
  mounted() {
    core.SmoothScrollbar();
  },
  methods: {
    sidebarMini() {
      core.triggerSet();
    },
  },
  data() {
    return {
      SideBarItems,
    };
  },
  computed: {
    filteredSidebar() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user?.roles?.some((role) => role?.name === "SuperAdmin")) {
        return this.SideBarItems;
      }
      return this.SideBarItems.filter((item) => {
        if (item.children) {
          item.children = item.children.filter((child) => {
            return this.$hasPerm(
              child?.permission?.action_name,
              child?.permission?.module_name
            );
          });
        }
        return (
          this.$hasPerm(
            item?.permission?.action_name,
            item?.permission?.module_name
          ) || item.children?.length
        );
      });
    },
  },
};
</script>
<style lang="scss">
.iq-sidebar {
  .left-icon-close {
    font-size: 30px;
    margin: 0 10px;
    cursor: pointer;
    display: none;
    color: var(--iq-primary);
    @media (max-width: 1300px) {
      display: flex;
    }
  }
}
</style>
