<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom mx-0">
      <!-- <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="{ name: 'dashboard' }" class="logo">
            <div class="iq-light-logo">
              <img
                src="../../../../assets/images/logo.png"
                class="img-fluid"
                alt="logo"
              />
            </div>
            <div class="iq-dark-logo">
              <img
                src="../../../../assets/images/logo.png"
                class="img-fluid"
                alt="logo"
              />
            </div>
            <span>{{ $t("global.appName") }}</span>
          </router-link>
        </div>
      </div> -->
      <nav
        class="navbar navbar-expand-lg navbar-light px-2 d-flex justify-content-between align-items-center"
      >
        <div class="navbar-left d-flex align-items-center">
          <div class="align-self-center">
            <div class="wrapper-menu" @click="sidebarMini">
              <div class="main-circle">
                <i class="ri-menu-line"></i>
              </div>
              <div class="hover-circle">
                <i class="ri-menu-line"></i>
              </div>
            </div>
          </div>
          <h4 class="mx-2 text-primary">
            {{ title }}
          </h4>
        </div>

        <div class="nav-right d-flex">
          <b-collapse id="nav-collapse" class="d-block" is-nav>
            <ul class="navbar-nav ml-auto navbar-list">
              <li class="nav-item" v-nav-toggle>
                <a class="search-toggle iq-waves-effect language-title" href="#"
                  ><img
                    :src="selectedLang.image"
                    alt="img-flaf"
                    class="img-fluid mr-1"
                    style="height: 16px; width: 16px" /><i
                    class="ri-arrow-down-s-line"
                  ></i
                ></a>
                <div class="iq-sub-dropdown">
                  <a
                    class="iq-sub-card"
                    href="javascript:void(0)"
                    v-for="(lang, i) in langsOptions"
                    :key="`Lang${i}`"
                    @click="langChange(lang)"
                  >
                    <img
                      :src="lang.image"
                      alt="img-flaf"
                      class="img-fluid mr-2"
                    />{{ lang.title }}
                  </a>
                </div>
              </li>
            </ul>
          </b-collapse>

          <!-- logout button -->
          <b-button
            variant="danger"
            class="mx-2 align-self-center"
            @click="logout"
          >
            <i class="ri-logout-box-line"></i>
            <span>{{ $t("nav.user.signout") }}</span>
          </b-button>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import GlobalSearch from "../../search/GlobalSearch";
import { core } from "../../../../config/pluginInit";
import langChange from "../../../../Mixins/langchange";
import { getLanguage } from "../../../../Utils/helper";
import DefaultUserImg from "../../../../assets/images/user/avatar.svg";
// import Lottie from '../../../../components/core/lottie/Lottie'
import api from "../../../../axios";
export default {
  name: "HeaderStyle",
  mixins: [langChange],
  components: {
    // HoverMenu,
    GlobalSearch,
    // CollapseMenu
    // Lottie
  },
  data() {
    return {
      globalSearch: "",

      selectedLang: getLanguage(),
      rtl: false,
      DefaultUserImg,
      userData: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    if (!this.horizontal) {
      document.addEventListener("click", this.closeSearch, true);
    }
  },
  methods: {
    sidebarMini() {
      core.triggerSet();
    },
    openSearch() {
      this.showSearch = true;
      this.showMenu = "iq-show";
      this.globalSearch = "";
      // if (document.getElementById('searchbox-datalink') !== null) {
      //   document.getElementById('searchbox-datalink').classList.add('show-data')
      // }
    },
    closeSearch(event) {
      const classList = event.target.classList;
      if (
        !classList.contains("searchbox") &&
        !classList.contains("search-input")
      ) {
        this.removeClass();
      }
    },
    removeClass() {
      this.showSearch = false;
      this.showMenu = "";
      this.globalSearch = "";
      if (
        document.getElementById("searchbox-datalink") !== null &&
        document.getElementById("searchbox-datalink") !== undefined
      ) {
        document
          .getElementById("searchbox-datalink")
          .classList.remove("show-data");
      }
    },
    async logout() {
      await api().post("admin/auth/signout");
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      localStorage.removeItem("permissions");
      this.$router.push({ name: "auth.sign-in" });
    },
    ...mapActions({
      removeToCart: "Ecommerce/removeToCartAction",
      langChangeState: "Setting/setLangAction",
    }),
  },

  computed: {
    ...mapGetters({
      cartCount: "Ecommerce/cartCountState",
      cartItems: "Ecommerce/cartState",
      // bookmark: 'Setting/bookmarkState',
      langsOptions: "Setting/langOptionState",
      // selectedLang: 'Setting/langState'
    }),
    userName() {
      // const itemAr = this.userData.translations?.find(o => o.lang === 'ar')
      // const itemEn = this.userData.translations?.find(o => o.lang === 'en')
      // return this.selectedLang?.value === 'ar' ? itemAr.name : itemEn.name
      return this.userData?.user_name
        ? this.userData?.user_name
        : this.$t("users.item");
    },
    title() {
      let text = this.$route.path.split("/").filter((item) => item)[0];
      if (text == undefined) {
        return this.$t("sidebar.dashboard");
      }
      let words = text.split("-");
      let camelCaseText = words[0];

      for (let i = 1; i < words.length; i++) {
        let capitalizedWord =
          words[i].charAt(0).toUpperCase() + words[i].slice(1);
        camelCaseText += capitalizedWord;
      }
      return this.$t(`${camelCaseText}.items`);
    },
  },
};
</script>
